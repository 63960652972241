<template>
  <div class="home">
    <div class="home-left">
      <div class="swiper">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item v-for="item in homeswiper" :key="item.id">
            <img :src="global.imgUrl + item.imgUrl" alt="" />
            <!-- <div class="swiper-title">{{ item.title }}</div> -->
            <div class="date-message">
              <div>{{ item.title }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="main-body">
        <div class="main-body-header">
          <div class="newmessage">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-zu333"></use>
            </svg>
            最新资讯
          </div>
          <div class="checkmore" @click="pagego('/consulting')">查看更多</div>
        </div>
        <div class="main-body-item" v-for="(item, index) in Consultationlist" :key="item.id" @click="godetails(item, 2)">
          <div class="img-box">
            <img class="left-img" :src="global.imgUrl + item.imgUrl" alt="" />
          </div>
          <div class="items-right">
            <p class="title">
              {{ item.title }}
            </p>
            <p @click="godetails(item, 2)">{{ item.zhaiyao }}</p>
            <div class="items-right-bottom">
              <div>
                <!-- <i class="iconfont icon-leftfont-104"></i> -->
                <img class="iconfont" :src="imgIcon.imgPlace" />
                <div v-if="item.author">{{ item.author }}</div>
                <div v-else>carbontech</div>
              </div>
              <div>
                <!-- <i class="iconfont icon-naozhong2"></i> -->
                <img class="iconfont" :src="imgIcon.imgTime" />
                {{ item.addTime.slice(0, 10) }}
              </div>
            </div>
          </div>
        </div>
        <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination> -->
      </div>
    </div>
    <div class="home-right">
      <div class="hotfollow">
        <div class="hotfollow-head">
          <div>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-zu336"></use>
            </svg>
            热门课程
          </div>
          <div class="checkmore" @click="pagego('/curriculum')">查看更多</div>
        </div>
        <div class="hotfollow-body">
          <div class="hotfollow-item" v-for="(item, index) in hotclasslist" @click="godetails(item, 7)">
            <div class="item-img-box">
              <img :src="global.imgUrl + item.imgUrl" alt="" />
              <div class="time-box">{{ item.articleFields[2].fieldValue }}</div>
            </div>
            <div class="hotfollow-right-box">
              <div class="hotfollow-item-title">
                <p>{{ item.title }}</p>
              </div>
              <!-- <div class="hotfollow-item-play">立即播放</div> -->
              <div class="hotfollow-item-details">
                <div class="payfor">
                  {{
                    item.articleFields[0].fieldValue == "1" ? "免费" : "付费"
                  }}
                </div>
                <div>{{ item.articleFields[4].fieldValue }}人已观看</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group-box">
        <div class="group-header">
          <p class="header-title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            圈子
          </p>
          <!-- <div class="see-more">查看更多</div> -->
        </div>
        <div class="group-item">
          <!-- <div class="group-items" v-for="item in grouplist"   @click="
                $store.commit('setVideoDialog', { show: true, item: item })
              "> -->
          <div class="group-items" v-for="item in grouplist" @click="goCircleDetails(item)">
            <i class="iconfont icon-a-zu338"></i>
            <svg class="icon icon-a-zu337" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            <div class="title">
              <p>{{ item.title }}</p>
            </div>
            <div class="join">
              <i class="iconfont icon-a-zu340"></i>
              加入
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="Expertlibray">
        <div class="Expertlibray-header">
          <div class="header-title">
              <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-zhuanjiaxinxi"></use>
            </svg>
            专家库
          </div>
          <div class="checkmore">查看更多</div>
        </div>
        <div class="expertlibray-swiper">
          <el-carousel :interval="4000" type="card" height="200px">
            <el-carousel-item v-for="item in 6" :key="item">
              <img src="@/assets/images/zjtx.png" alt="" />
              <div class="message-box">
                <p class="name">王守业</p>
                <p class="position">· 苏州大学教授 ·</p>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div> -->
    </div>
    <video-dialog />
  </div>
</template>

<script>
import axios from "axios";
import {
  getWxloginin,
  Grouplist,
  getCourselist,
  getConsultationlist,
  gethomeswiper,
  gethotclasslist,
  getzjk,
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "index",
  components: {
    videoDialog: () => import("@/components/Circle"),
  },
  data() {
    return {
      currentPage: 1,
      grouplist: [],
      Consultationlist: [],
      hotCourselist: [],
      total: 1,
      homeswiper: [],
      hotclasslist: [],
      zjklist: [],
      imgIcon: imgObj,
    };
  },
  methods: {

    async getgrouplist() {
      Grouplist().then((res) => {
        console.log("圈子标题", res);
        this.grouplist = res.data;
      });
    },
    handleCurrentChange(e) {
      console.log(e);
      getConsultationlist(e).then((res) => {
        this.Consultationlist = res.data;
      });
    },
    handleSizeChange(e) {
      console.log(e);
    },
    async getData(classifyId) {
      axios.get(`https://api2.dtcms.polydt.com/client/article/74?PageIndex=1&PageSize=8&Fields=id,title,imgUrl,zhaiyao,author,addTime`).then(res => {
        console.log("资讯列表", res.data)
        this.Consultationlist = res.data.slice(0, 6);
      })

    },
    async start() {
      // getConsultationlist(1).then((res) => {
      //   console.log("资讯列表", res);
      //   this.Consultationlist = res.data;
      //   this.total =parseInt(res.headers["x-pagination"].split(",")[0].split(":")[1])
      // });
      getCourselist(1).then((res) => {
        console.log("课程列表", res);
        this.hotCourselist = res.data;
      });
      gethomeswiper().then((res) => {
        console.log("首页轮播", res);
        this.homeswiper = res.data;
      });
      gethotclasslist().then((res) => {
        console.log("热门课程", res);
        this.hotclasslist = res.data;
      });
      getzjk().then((res) => {
        console.log("专家库", res);
        this.zjklist = res.data;
      });
    },
    async pagego(path) {
      await this.$store.commit("setactivepath", path);
      this.$router.push(path);
    },
    godetails(item, type) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      let routeData = this.$router.resolve({
        name: "DetailsPage",
        query: {
          id: item.id || item.Id,
          type,
          title: item.title || item.Title,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goCircleDetails(item) {
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      let routeData = this.$router.resolve({
        name: "circleDetailsPage",
        query: {
          id: item.id || item.Id,
          source: Number(item.source),
          title: item.title || item.Title,
          linkUrl: item.linkUrl
        },
      });
      window.open(routeData.href, "_blank");
    },
    submitOAuth(provider, code) {
      let href = `${window.location.protocol}//carbonlinks.cn/index?provider=wechat`;
      let uri = encodeURIComponent(href);
      let data = {
        siteId: 14,
        provider: provider,
        code: code,
        redirectUri: uri,
      };
      getWxloginin(data).then((res) => {
        localStorage.setItem("token", res.data.accessToken);
        console.log(res);
      });
    },
  },
  created() {
    // let provider = this.$route.query.provider;
    // let code = this.$route.query.code;
    // if (provider && code) {
    //   this.submitOAuth(provider, code);
    // }
    this.getgrouplist();
    this.getData();
    this.start();
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;

  .checkmore {
    cursor: pointer;
    color: #9a9a9a;
    font-size: 16px;

    a {
      color: #9a9a9a;
    }
  }

  .see-more {
    cursor: pointer;
  }

  .home-left {
    width: 100%;
    margin-right: 20px;

    .swiper {
      /deep/ .el-carousel__container {
        height: 426px !important;
      }

      img {
        width: 100%;
        height: 426px;
      }

      .date-message {
        bottom: 0px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #040404 100%);
        color: white;
        position: absolute;
        padding: 50px 40px;
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        overflow: hidden;
        /*超出部分隐藏*/
        white-space: nowrap;
        /*禁止换行*/
        text-overflow: ellipsis;

        /*省略号*/
        .date-box {
          display: flex;
          justify-content: space-between;
          opacity: 0.63;
          padding-bottom: 5px;

          .iconfont {
            font-size: 12px;
            margin-right: 5px;
          }
        }
      }

      .swiper-title {
        position: absolute;
        bottom: 45px;
        left: 57px;
        color: white;
        letter-spacing: 3px;
        font-weight: bold;
        font-size: 20px;
      }

      background: gainsboro;

      /deep/ .el-carousel__indicators--horizontal {
        left: 13%;
        bottom: 3%;
      }

      /deep/ .el-carousel__button {
        // 默认按钮的颜色大小等
        width: 24px;
        height: 4px;
        border: none;
        background: hsla(0, 0%, 100%, 0.4);
        opacity: 1;
      }

      /deep/ .is-active .el-carousel__button {
        // 选中图片的颜色
        background: #01396a;
        opacity: 1;
      }

      /deep/ .el-carousel__arrow {
        border-radius: 0;
      }

      /deep/ .el-carousel__arrow--right {
        right: 0;
      }

      /deep/ .el-carousel__arrow--left {
        left: 0;
      }
    }

    .main-body {
      margin: 20px 0;

      .main-body-header {
        background: white;
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
        border-bottom: 1px solid #e9e9e9;

        .newmessage {
          font-size: 16px;
          display: flex;
          align-items: center;

          .icon {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
        }
      }

      .main-body-item {
        background: white;
        padding: 15px 20px;
        height: 144px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;

        &:hover {
          cursor: pointer;
          background: #fafafa;
          // img {
          //   scale: 1.3;
          // }
        }

        .img-box {
          overflow: hidden;
          width: 308px;
        }

        .left-img {
          width: 100%;
          height: 100%;
          margin-right: 20px;
          transition: 1s;
          cursor: pointer;

          &:hover {
            scale: 1.3;
          }
        }

        .items-right {
          width: 100%;
          position: relative;
          margin-left: 20px;

          .title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .title:hover {
            color: #0966a9;

          }

          .items-right-bottom {
            width: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #a9a9a9;

            div {
              display: flex;
            }

            // div:nth-child(1) {
            //   display: flex;
            // }
            .iconfont {
              color: #848484;
              margin-right: 10px;
              width: 16px;
              height: 16px;
            }
          }

          p {
            margin: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .title {
            font-size: 20px;
            font-weight: bold;
          }

          p:nth-child(2) {
            color: gray;
            font-size: 14px;
            padding-top: 10px;
            line-height: 24px;
            overflow: hidden;
            line-height: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }

      .el-pagination {
        margin-top: 50px;
        display: flex;
        justify-content: center;

        /deep/ li {
          margin: 0 8px !important;
          min-width: 36px !important;
          height: 34px;
          line-height: 36px;
        }

        /deep/ .active {
          color: white;
          background: #0966a9;
        }

        /deep/ li:hover {
          color: white;
          background: #0966a9;
        }

        /deep/ button {
          margin: 0 8px !important;
          height: 34px;
          line-height: 36px;
        }

        /deep/ .btn-next {
          padding: 0;
        }

        /deep/ .btn-prev {
          padding: 0;
        }

        /deep/ button:hover {
          color: white;
          background: #0966a9;
        }

        /deep/ span {
          margin-right: 30px;
          line-height: 36px;
        }
      }
    }
  }

  .home-right {
    width: 40%;

    .hotfollow {
      background: white;

      .hotfollow-head {
        display: flex;
        align-items: flex-end;
        padding: 20px 20px;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        font-size: 16px;
        font-weight: 400;

        .icon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
          position: relative;
          top: 3px;
        }
      }

      .hotfollow-body {
        .hotfollow-item {
          padding: 10px 20px;
          padding-right: 30px;
          border-bottom: 1px solid #e9e9e9;
          display: flex;
          // width: 83%;
          justify-content: space-between;

          .item-img-box {
            margin-top: 5px;
            position: relative;
            cursor: pointer;

            img {
              width: 180px;
              height: 110px;
            }

            .time-box {
              position: absolute;
              bottom: 10px;
              right: 10px;
              color: white;
              font-size: 12px;
              padding: 5px 10px;
              background: rgba(0, 0, 0, 0.438);
            }
          }

          .hotfollow-right-box {
            width: 100%;
            margin-left: 20px;
            position: relative;
          }

          .hotfollow-item-title {
            width: 100%;
            color: #272727;
            margin-top: 5px;
            cursor: pointer;

            p {
              margin: 0;
              font-size: 14px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }

          .hotfollow-item-play {
            width: 6.25rem;
            background: white;
            text-align: center;
            margin-top: 15px;
            padding: 0.375rem 0px;
            font-size: 0.8125rem;
            color: #616161;
            cursor: pointer;
            border: 0.0625rem solid #616161b2;
          }

          .hotfollow-item-details {
            width: 100%;
            margin-top: 10px;
            font-size: 14px;
            position: absolute;
            bottom: 2px;

            .payfor {
              background: #f2f2f2;
              color: #0966a9;
              padding: 4px 11px;
              width: 30px;
              margin-bottom: 10px;
            }

            div:nth-child(2) {
              color: red;
              color: #747474;
              font-size: 12px;
            }
          }

          &:hover {
            cursor: pointer;
            background: #fafafa;
          }
        }
      }
    }

    .group-box {
      background: white;
      margin-top: 20px;

      .group-header {
        padding-top: 16px;
        display: flex;
        justify-content: space-between;

        .see-more {
          line-height: 25px;
          padding-right: 20px;
          color: #9a9a9a;
        }

        border-bottom: 1px solid #e9e9e9;

        .header-title {
          font-size: 16px;
          color: #0b1d47;
          margin-top: 0;
          margin-left: 20px;
          margin-bottom: 20px;

          .icon {
            width: 18px;
            height: 18px;
            margin-right: 15px;
            position: relative;
            top: 5px;
          }

          span {
            font-size: 18px;
            font-weight: normal;
            color: #707070;
          }
        }

        .visiting-card {
          height: 20px;
          line-height: 20px;
          padding: 5px 15px;
          margin-top: 0;
          font-size: 14px;
          border-radius: 15px;
          margin-left: 4%;
          cursor: pointer;
        }

        .visiting-card-active {
          background: #b7d9ef65;
        }

        .wechat-group {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          padding: 5px 15px;
          border-radius: 15px;
          margin-left: 15px;
          cursor: pointer;
        }

        .wechat-group-active {
          background: linear-gradient(231deg, #2fb6b649 0%, #128f9325 100%);
        }
      }

      .group-item {
        width: 100%;

        .group-items {
          cursor: pointer;
          padding: 20px 20px;
          display: flex;
          position: relative;
          align-items: flex-end;

          .icon-a-zu337 {
            width: 20px;
            height: 20px;
            display: none;
            position: relative;
            top: 0px;
          }

          &:hover {
            padding: 20px 20px;
            background: #fafafa;

            .join {

              // background: #31448342;
              .iconfont {
                color: #0966a9;
              }
            }

            .title {
              color: #314483;

              // font-weight: bold;
              cursor: pointer;
            }

            // .icon-a-zu338 {
            //   display: none;
            // }
            // .icon-a-zu337 {
            //   display: block;
            // }
          }

          i {
            font-size: 20px;
          }

          .title {
            margin-left: 20px;
            margin-top: 2px;

            p {
              width: 210px;
              margin: 0;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .join {
            padding: 5px 15px;
            position: absolute;
            right: 10px;
            font-size: 14px;
            text-align: center;

            .iconfont {
              color: #cbcbcb;
              font-size: 16px;
            }
          }
        }
      }
    }

    .Expertlibray {
      background: white;
      margin-top: 20px;

      .Expertlibray-header {
        display: flex;
        align-items: flex-end;
        padding: 20px 20px;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        font-size: 20px;
        font-weight: 400;

        .header-title {
          font-size: 20px;
          color: #0b1d47;
          margin: 0;

          .icon {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            position: relative;
            top: 3px;
          }

          span {
            font-size: 18px;
            font-weight: normal;
            color: #707070;
          }
        }

        .iconfont {
          font-size: 25px;
        }
      }

      .expertlibray-swiper {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 25px;
        color: black;
        text-align: center;

        /deep/.el-carousel__mask {
          opacity: 0.7 !important;
        }

        .el-carousel__item--card.is-active {
          box-shadow: 0px 3px 36px 1px rgba(0, 0, 0, 0.16);
          opacity: 1;
          display: flex;
          flex-direction: column;
        }

        .el-carousel__item,
        .el-carousel__mask {
          height: 90%;
        }

        .message-box {
          height: 100%;
          background: white;
          padding-bottom: 10px;
        }

        img {
          width: 100%;
          height: 60%;
        }

        p {
          margin: 0;
          padding: 10px 0;
        }

        p:nth-child(2) {
          width: 70%;
          margin: 0 auto;
          color: #1e1e1e;
          font-size: 12px;
          background: #e9e9e9;
          border-radius: 7px;
          padding: 4px 5px;
        }

        /deep/.el-carousel__indicators--outside {
          display: none;
        }

        /deep/.el-carousel__container {
          height: 256px !important;
        }
      }
    }
  }
}</style>